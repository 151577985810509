<template>
  <Toast />
  <ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer">AGENDA</a>
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3 active"
        >REPORTE REPORTE DE AGENDA</span
      >
    </li>
  </ul>
  <div class="surface-section px-4 py-5 md:px-12 lg:px-12">
    <div
      class="flex align-items-center flex-column lg:justify-content-center lg:flex-row"
    >
      <span
        style="
          border-bottom: 1px solid #002236;
          font-size: 1.5em;
          color: #002236;
          margin-bottom: none;
        "
      >
        <strong>REPORTE GENERAL DE AGENDA</strong>
      </span>
    </div>
    <div class="col-12">
      <div class="col-12 p-fondo">
        <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-2">
            <span><strong>FECHA INICIO: </strong></span>
            <Calendar
              v-model="fecha_inicio"
              :showIcon="true"
              dateFormat="dd/mm/yy"
            ></Calendar>
          </div>
          <div class="field col-12 md:col-2">
            <span><strong>FECHA FIN: </strong></span>
            <Calendar
              v-model="fecha_fin"
              :showIcon="true"
              dateFormat="dd/mm/yy"
            ></Calendar>
          </div>
          <div class="field col-12 md:col-4">
            <span><strong>BUSCAR MÉDICO</strong></span>
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <i class="pi pi-user"></i>
              </span>
              <AutoComplete
                v-model="medicoSelected"
                :suggestions="medicos"
                field="nombre_medico"
                v-tooltip.top="'Buscar Médico'"
                optionValue="id"
                @item-select="BuscarFiltro"
                @keyup.enter="buscarMedico"
              />
              <Button
                icon="pi pi-search"
                class="p-button-warning p-button-sm"
                v-tooltip.top="'Buscar Médico'"
                @click="buscarMedico"
              />
            </div>
          </div>

          <div class="field col-12 md:col-2">
            <span><strong>ESTADO: </strong></span>
            <Dropdown
              v-model="estadoSelected"
              :options="[
                { label: 'TODOS', value: 3 },
                { label: 'CANCELADO', value: 0 },
                { label: 'PENDIENTE', value: 1 },
                { label: 'ATENDIDO', value: 2 },
              ]"
              optionLabel="label"
              optionValue="value"
              :filter="true"
              @change="BuscarFiltro"
            >
            </Dropdown>
          </div>
          <div class="field col-12 md:col-2">
            <span><strong>ACCIONES: </strong></span>
            <Button
              label="BUSCAR"
              icon="pi pi-search"
              class="p-button-primary mr-2"
              v-tooltip.top="'Buscar Productos'"
              :loading="enviando"
              :disabled="enviando"
              @click="BuscarFiltro"
            />
          </div>
        </div>
      </div>
      <div class="mt-4">
        <DataTable
          ref="dt_agendas"
          key="id"
          :value="agendas_medicas"
          responsiveLayout="scroll"
          :loading="enviando"
          class="p-datatable-sm"
          :filters="buscar"
          responsive="true"
          stripedRows
          showGridlines
        >
          <template #header>
            <div
              class="flex flex-column md:flex-row md:justify-content-end md:align-items-end"
            >
              <span class="block mt-2 md:mt-0 p-input-icon-left mr-2">
                <i class="pi pi-search" />
                <InputText
                  v-model="buscar['global'].value"
                  placeholder="Buscar Agendas..."
                />
              </span>
              <span class="block mt-2 md:mt-0 p-input-icon-left">
                <Button
                  label="EXPORTAR"
                  type="button"
                  icon="pi pi-file-excel"
                  class="p-button-outlined p-button-success"
                  v-tooltip.top="'Exportar Agendas'"
                  :loading="exportando"
                  @click="exportAgendas()"
                />
              </span>
            </div>
          </template>
          <template #loading>
            <div class="flex align-items-center justify-content-center">
              <ProgressSpinner />
            </div>
          </template>
          <template #empty>
            <span
              class="flex align-items-center justify-content-center"
              style="
                background-color: #f8d7da;
                padding: 5px;
                border-radius: 5px;
                color: #721c24;
              "
            >
              Debe aplicar filtros para ver resultados en la tabla!</span
            >
          </template>
          <Column header="#">
            <template #body="slotProps">
              {{ 1 + slotProps.index }}
            </template>
          </Column>
          <Column field="nombre_medico" header="MÉDICO"></Column>
          <Column field="nombre_cliente" header="CLIENTE"></Column>
          <Column field="telefono" header="TELÉFONO"></Column>
          <Column field="nombre_consultorio" header="SERVICIO"></Column>
          <Column field="convenio" header="CONVENIO"></Column>
          <Column field="fecha_inicio" header="HORA">
            <template #body="{ data }">
              {{
                fechaHora(
                  data.fecha_inicio.split(" ")[0],
                  data.fecha_inicio.split(" ")[1]
                )
              }}
            </template>
          </Column>
          <Column
            field="detalle"
            header="OBSERVACIÓN"
            style="font-weight: bold"
          ></Column>
          <Column field="realizado_por" header="REALIZADO POR">
            <template #body="{ data }">
              <span v-tooltip.top="data.realizado_por">
                {{ textoCorto(data.realizado_por) }}
              </span>
            </template>
          </Column>
          <Column field="estado" header="ESTADO">
            <template #body="{ data }">
              <span
                class="customer-badge"
                :class="{
                  'status-new': data.estado === 0,
                  'status-proposal': data.estado === 1,
                  'status-qualified': data.estado === 2,
                }"
              >
                {{ textoEstado(data.estado) }}
              </span>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import { useAuth } from "@/stores";
import AgendaService from "@/service/AgendaService";
import MedicoService from "@/service/MedicoService";

export default {
  data() {
    return {
      enviando: false,
      buscar: {
        global: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
      },
      agendas_medicas: [],
      fecha_inicio: new Date(),
      fecha_fin: new Date( // fecha por ultimo dia del mes
        new Date().getFullYear(),
        new Date().getMonth() + 1,
        0
      ),
      medicoSelected: 0,
      medicos: [{ id: 0, nombre_medico: "TODOS" }],
      estadoSelected: 3,
      existe_medico_logueado: false,
      exportando: false,
    };
  },
  auth: null,
  agendaService: null,
  medicoService: null,

  created() {
    this.auth = useAuth();
    this.agendaService = new AgendaService();
    this.medicoService = new MedicoService();
  },
  methods: {
    textoCorto(texto) {
      if (texto.length > 10) return texto.substring(0, 10) + "...";
      return texto;
    },
    exportAgendas() {
      this.exportando = true;
      let datos = {
        fecha_inicio: this.fecha_inicio,
        fecha_fin: this.fecha_fin,
        medico_id: this.medicoSelected,
        estado: this.estadoSelected,
      };
      this.agendaService
        .exportarAgendas(datos)
        .then((response) => {
          this.exportando = false;
          if (response.status == 400) {
            this.$toast.add({
              severity: "error",
              summary: "ERROR",
              detail: response.message,
              life: 300,
            });
            return;
          }
          window.open(response.url, "_blank");
        })
        .catch((error) => {
          this.exportando = false;
          console.log(error);
        });
    },
    fechaHora(fecha_inicio, hora) {
      if (this.fecha_inicio === fecha_inicio) return hora;
      else return hora;
    },
    BuscarFiltro() {
      this.agendas_medicas = [];
      this.enviando = true;
      this.cargarAgendas();
    },
    cargarAgendas() {
      let datos = {
        fecha_inicio: this.fecha_inicio,
        fecha_fin: this.fecha_fin,
        medico_id: this.medicoSelected,
        estado: this.estadoSelected,
      };
      this.agendaService
        .buscarAgendas(datos)
        .then((response) => {
          this.agendas_medicas = response.agendas_medicas || [];
          this.enviando = false;
        })
        .catch((error) => {
          this.enviando = false;
          console.log(error);
        });
    },
    /* cargarMedicos() {
      this.medicoService.getMedicosAll().then((data) => {
        this.medicos = data.medicos || [];

        let medico = this.medicos.find(
          (medico) => medico.nombre_medico === this.auth.user.name
        );
        if (medico) {
          this.medicoSelected = medico.id;
          this.existe_medico_logueado = true;
        } else {
          this.medicoSelected = 0;
          this.medicos.unshift({ id: 0, nombre_medico: "TODOS" });
        }
        this.BuscarFiltro();
      });
    }, */
    buscarMedico() {
      let datos = {
        texto: this.medicoSelected,
      };
      this.medicoService.buscarMedicoXNombreOtraBD(datos).then((response) => {
        if (response.status == 400 || response.medicos.length == 0) {
          this.$toast.add({
            severity: "error",
            summary: "ERROR",
            detail: "No se encontró el médico",
            life: 300,
          });
          return;
        }
        this.medicos = [...response.medicos];
      });
    },
    textoEstado(estado) {
      switch (estado) {
        case 0:
          return "CANCELADO";
        case 1:
          return "PENDIENTE";
        case 2:
          return "ATENDIDO";
        default:
          return "DESCONOCIDO";
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-fondo {
  background-color: #7bffae;
  border-radius: 0.5em;
  border: 1px solid #000000;
}
.p-invalid {
  color: red;
}
</style>
